












































































































import { Component, Prop, Vue } from "vue-property-decorator"
import Axios from 'axios'
import DniField from '@/components/DniField.vue'
import ReusableButton from '@/components/ReusableButton.vue'
import { Configuration, TitleTemplatePublic, SelectedCountry, RedCapital } from '@/configuration'
import ReCaptcha from '@/components/ReCaptcha.vue'
import API from '@/api'
import { isRutValid, DniTrim, Uri } from '@/utils'
import { EventBus } from '@/main'

@Component({
  metaInfo: {
    title: 'Registrarse',
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
  },
  data() {
    return {
      RedCapital,
      SelectedCountry,
      terms: false,
      showError: false,
      type: [
        {
          id: 2,
          value: 'Invertir',
        },
        {
          id: 3,
          value: 'Solicitar',
        },
      ],
      user: {
        name: '',
        lastname: '',
        email: '',
        password: '',
        c_password: '',
        dni: '',
        phone: '',
        type: 3,
      },
      rules: {
        name: [
          (v: any) => !!v || 'Nombre es requerido',
        ],
        lastname: [
          (v: any) => !!v || 'Apellido es requerido',
        ],
        rut: [
          // @ts-ignore
          (v: any) => !!v || `${RedCapital[SelectedCountry].DniName} es requerido`,
          // @ts-ignore
          (v: any) => /^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/.test(v) || `${RedCapital[SelectedCountry].DniName} invalido Ej: 12.345.678-9`
        ],
        ruc: [
          // @ts-ignore
					(v: any) => !!v || `${RedCapital[SelectedCountry].DniName} es requerido`,
					// @ts-ignore
					(v: any) => v.toString().length != 11 || `${RedCapital[SelectedCountry].DniName} no válido`,
        ],
        email: [
          (v: any) => !!v || 'E-mail es requerido',
          (v: any) => /^.+@.+\..+/gmi.test(v) || 'El e-mail tiene que ser valido'
        ],
        password: [
          (v: any) => !!v || 'Contraseña es requerida',
          (v: any) => v.length >= 4 || 'Debe tener al menos 4 caracteres.'
        ],
        phone: [
          (v: any) => !(/[^\d]/gim.test(v)) || 'Tiene que ser solo numeros',
          (v: any) => !!v || 'Teléfono es requerido',
        ],
      },
      dialog: false,
      btn: {
        clickable: false,
        loading: false,
        isOk: null,
        successText: 'Guardado correctamente',
        errorText: 'Error interno',
      },
      errorMessage: [],
      passwordError: null,
    };
  },
  watch: {
    user: {
      handler() {
        if(this.$data.terms == true) {
          // @ts-ignore
          if (this.$refs.form.validate()) {
            if (this.$data.user.password !== this.$data.user.c_password) {
              this.$data.passwordError = null
              this.$data.passwordError = "Las contraseñas ingresadas no coinciden"
              this.$data.btn.clickable = false
            } else {
              this.$data.btn.clickable = true
              this.$data.passwordError = null
            }
          } else {
            this.$data.btn.clickable = false
            this.$data.passwordError = null
          }
        } else {
          this.$data.btn.clickable = false
        }
      },
      deep: true,
    },
    terms(val) {
      // @ts-ignore
      if (this.$refs.form.validate()) {
        this.$data.btn.clickable = val
      }
    },
  },
  methods: {
    termError() {
      this.$data.showError = true
      setTimeout(() => {
        this.$data.showError = false
      }, 2500)
    },
    async onSubmit(response) {
      // @ts-ignore
      if (this.$refs.form.validate()) {
        this.$data.btn.loading = true
        this.$data.errorMessage = []
        API.register(this.$data.user.name, this.$data.user.lastname, this.$data.user.email, this.$data.user.password, this.$data.user.c_password, DniTrim.string(this.$data.user.dni), this.$data.user.phone, this.$data.user.type).then( (res: any) => {
          if (res.status != 200) {
            this.$data.btn.isOk = false
            if (res.message) {
              EventBus.$emit('snack-error', res.message)
              this.$data.errorMessage.push(res.message)
            }
          } else {
            this.$data.btn.isOk = true
            this.$data.dialog = true
            API.login(this.$data.user.dni, this.$data.user.password).then((data: any) => {
              this.$store.commit('setUser', data.usuario)
              this.$store.commit('setToken', data.access_token)
              this.$store.commit('setExpireTime', data.expires_at)

              setTimeout(() => {
                this.$router.push({
                  path: `${this.$route.query.to}`
                })
              }, Configuration.Redirection.timeout * 2)
            })
          }
        }).catch( (error: any) => {
          this.$data.btn.isOk = false
          this.$data.errorMessage.push("Intente nuevamente")
          EventBus.$emit('snack-error', error.message)

        })
      }
    },
    doRecaptcha() {
      // @ts-ignore
      this.$refs.recaptchaRegister.execute()
    },
    login() {
      this.$data.showMenu = false
      this.$data.canShowUserMenu = false
      EventBus.$emit('open-login-dialog', this.$route.query.to != null ? `${this.$route.query.to}` : '')
    },
  },
  components: {
    DniField,
    ReusableButton,
    ReCaptcha,
  },
})
export default class Register2 extends Vue {}
