




import { Component, Vue } from "vue-property-decorator";

@Component({
  data() {
    return {
      siteKey: "6Ld5Q6wUAAAAAP_7Wv0YR6uhQptGNLWVt6EMZQ9T",
      widgetId: 0
    };
  },
  methods: {
    execute() {
      window.grecaptcha.execute(this.$data.widgetId);
    },
    reset() {
      window.grecaptcha.reset(this.$data.widgetId);
    },
    render() {
      if (window.grecaptcha) {
        this.$data.widgetId = window.grecaptcha.render("g-recaptcha", {
          siteKey: this.$data.siteKey,
          badge: "bottomleft",
          size: "invisible",
          callback: (response: any) => {
            this.$emit("verify", response);
            // @ts-ignore
            this.reset();
          }
        });
      }
    }
  },
  mounted() {
    // @ts-ignore
    this.render();
  }
})
export default class ReCaptcha extends Vue {}
